import React, { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import SEO from '../seo';

const TableWrapperDiv = styled.div`
    background-color: rgba(236, 236, 236, 0.2);
    height: 100%;
    padding: 40px 30px;
    width: 100%;
`;

const TableWrapper: FC = ({ children }) => {
	const { t } = useTranslation();
	return (
		<TableWrapperDiv>
			<SEO title={t('order.title')} />
			{children}
		</TableWrapperDiv>
	);
};
export default TableWrapper;
