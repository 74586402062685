/* eslint-disable */
import * as React from "react";

const ClockIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg height={12} width={12} xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M0 0h12v12H0z" data-name="Path 3794" fill="none" />
        <path
            d="M7.491.552H4.585v.99h2.906zM5.554 6.965h.969v-2.96h-.969zm3.89-3.266l.688-.7a5.4 5.4 0 00-.683-.7l-.688.7a4.292 4.292 0 00-2.722-.977 4.4 4.4 0 00-4.36 4.444 4.4 4.4 0 004.359 4.44 4.4 4.4 0 004.359-4.44 4.493 4.493 0 00-.954-2.767zM6.038 9.924a3.454 3.454 0 113.391-3.453 3.42 3.42 0 01-3.391 3.453z"
            data-name="Path 3795"
            fill="#646464"
        />
    </svg>
);

export default ClockIcon;
