import React, { FC } from 'react';
import styled from 'styled-components';
import RatingStarIcon from '../../../icons/ratingStarIcon';

const PanelInTableRatingWrapper = styled.div`
    align-items: center;
    background-color: #ffffff;
    border-radius: 10px;
    bottom: 5px;
    display: flex;
    height: 20px;
    justify-content: space-evenly;
    left: 5px;
    position: absolute;
    width: 75px;
`;
const PanelInTableRatingScore = styled.div`
    color: #000000;
    font-size: 12px;
    font-weight: 600;
    line-height: 12px;
`;
const PanelInTableRatingNumberOfOpinions = styled.div`
    color: #000000;
    font-size: 12px;
    line-height: 12px;
`;

interface PanelInTableRatingInterface {
    ratingScore: number;
    numberOfOpinions: number;
}

const PanelInTableRating: FC<PanelInTableRatingInterface> = ({ ratingScore, numberOfOpinions }) => (
	<PanelInTableRatingWrapper>
		<RatingStarIcon />
		<PanelInTableRatingScore>
			{ratingScore ? ratingScore.toFixed(1) : '0.0'}
		</PanelInTableRatingScore>
		<PanelInTableRatingNumberOfOpinions>
			{`(${numberOfOpinions})`}
		</PanelInTableRatingNumberOfOpinions>
	</PanelInTableRatingWrapper>
);
export default PanelInTableRating;
