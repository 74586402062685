import React, { FC } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import ArrowIcon from '../../../icons/arrowIcon';
import ClockIcon from '../../../icons/clockIcon';
import PanelInTableRating from './panelInTableRating';

const PanelInTableWrapper = styled(Link)`
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 3px 3px 5px 0 rgba(0,0,0,0.06);
    cursor: pointer;
    display: flex;
    height: 110px;
    margin-bottom: 20px;
    overflow: hidden;
    text-decoration: none;
    width: 100%;
`;
const PanelInTableBackgroundImage = styled.div`
    background-blend-mode: saturation;
     background-image: ${({ backgroundImagePath }) => `url(${backgroundImagePath})`};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    padding: 13px 23px;
    position: relative;
    width: 240px;
`;
const PanelInTableBackgroundImageFilter = styled.div`
    background: linear-gradient(rgba(30,30,28,0.51) 0%, rgba(255,255,255,0) 100%);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
`;
const PanelInTableTitleText = styled.div`
    color: #ffffff;
    font-size: 16px;
    font-weight: bold;
    position: absolute;
    z-index: 2;
`;
const PanelInTableContentSide = styled.div`
    display: flex;
    flex-direction: column;
    padding: 15px;
    width: 100%;
`;
const PanelInTableActionNameAndArrowWrapper = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
`;
const PanelInTableActionText = styled.div`
    color: #000000;
    font-size: 14px;
    font-weight: bold;
`;
const PanelInTableActionDescriptionsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
`;
const PanelInTableActionDescriptionText = styled.div`
    color: #646464;
    display: flex;
    font-size: 12px;
`;
const PanelInTableClockIconWrapper = styled.div`
    align-items: center;
    display: flex;
    margin-right: 6px;
`;

interface PanelInTableInterface {
    backgroundImagePath?: string;
    title?: string;
    actionName: string;
    actionUpperDescription: string;
    actionLowerDescription: string;
    to?: string;
    panelType?: PanelInTableTypes;
    ratingScore?: number;
    numberOfOpinions?: number;
}

export enum PanelInTableTypes {
    STANDARD_PANEL = 'STANDARD_PANEL',
    PANEL_IN_MENU = 'PANEL_IN_MENU',
}

const PanelInTable: FC<PanelInTableInterface> = ({
	backgroundImagePath, title, actionLowerDescription, actionUpperDescription, actionName, to, panelType = PanelInTableTypes.STANDARD_PANEL,
	numberOfOpinions, ratingScore,
}) => (
	<PanelInTableWrapper to={to}>
		<PanelInTableBackgroundImage backgroundImagePath={backgroundImagePath || '/table/tło.svg'}>
			{panelType === PanelInTableTypes.STANDARD_PANEL && (
				<>
					<PanelInTableBackgroundImageFilter />
					<PanelInTableTitleText>
						{title}
					</PanelInTableTitleText>
				</>
			)}
			{panelType === PanelInTableTypes.PANEL_IN_MENU && (
				<PanelInTableRating
					numberOfOpinions={numberOfOpinions}
					ratingScore={ratingScore}
				/>
			)}
		</PanelInTableBackgroundImage>
		<PanelInTableContentSide>
			<PanelInTableActionNameAndArrowWrapper>
				<PanelInTableActionText>
					{actionName}
				</PanelInTableActionText>
				{panelType === PanelInTableTypes.STANDARD_PANEL && <ArrowIcon />}
			</PanelInTableActionNameAndArrowWrapper>
			<PanelInTableActionDescriptionsWrapper>
				<PanelInTableActionDescriptionText>
					{actionUpperDescription}
				</PanelInTableActionDescriptionText>
				<PanelInTableActionDescriptionText>
					{panelType === PanelInTableTypes.PANEL_IN_MENU
                    && <PanelInTableClockIconWrapper><ClockIcon /></PanelInTableClockIconWrapper>}
					{actionLowerDescription}
				</PanelInTableActionDescriptionText>
			</PanelInTableActionDescriptionsWrapper>
		</PanelInTableContentSide>
	</PanelInTableWrapper>
);
export default PanelInTable;
