import * as React from "react";

const RatingStarIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg height={9.5} viewBox="0 0 9.5 10" width={10} xmlns="http://www.w3.org/2000/svg" {...props}>
        <g data-name="Group 5835" fill="none">
            <path d="M0 0h12v12H0z" data-name="Path 3791" />
            <path d="M0 0h12v12H0z" data-name="Path 3792" />
        </g>
        <g data-name="Group 5836">
            <path
                d="M6 8.635L9.09 10.5l-.82-3.515L11 4.62l-3.6-.305L6 1 4.6 4.315 1 4.62l2.73 2.365-.82 3.515z"
                data-name="Path 3793"
                fill="#ff8000"
            />
        </g>
    </svg>
);

export default RatingStarIcon;
