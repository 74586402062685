import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchRestaurantMenuDetailsAction } from '../state/menu.reducer';
import { fetchTableDetailsByIdAction } from '../state/table.reducer';
import { getQueryParam } from './queryParams';

const useGetIdsFromUrlAndFetch = (): { restaurantId: string, tableId: string } => {
	const dispatch = useDispatch();
	const restaurantId = getQueryParam('restaurantId');
	const tableId = getQueryParam('tableId');
	useEffect(() => {
		if (restaurantId) {
			dispatch(fetchRestaurantMenuDetailsAction(restaurantId));
		}

		if (tableId) {
			dispatch(fetchTableDetailsByIdAction(tableId));
		}
	}, []);

	return {
		restaurantId,
		tableId,
	};
};
export default useGetIdsFromUrlAndFetch;
