import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ImageTypeEnum } from 'restpay-api-lib/lib/@types/_enums/image.enum';
import TableWrapper from '../../components/table/tableWrapper';
import PanelInTable, { PanelInTableTypes } from '../../components/table/panelInTable/panelInTable';
import { GlobalState } from '../../state/@types/redux.interface';
import useGetIdsFromUrlAndFetch from '../../utils/useGetIdsFromUrlAndFetch';
import { changePickupModeAction, setCurrentRestaurantOrdersAction } from '../../state/cart.reducer';
import { getQueryParam } from '../../utils/queryParams';

const MenuTitle = styled.div`
    margin-bottom: 15px;
`;

const Menu = () => {
	const { t } = useTranslation();
	useGetIdsFromUrlAndFetch();
	const dispatch = useDispatch();
	const tableSelector = useSelector((state: GlobalState) => state.table);

	const {
		table, loading, error, fetched,
	} = tableSelector;

	useEffect(() => {
		const isPickup = getQueryParam('pickup');
		if (isPickup) {
			dispatch(changePickupModeAction(true));
		} else {
			dispatch(changePickupModeAction(false));
		}
		dispatch(setCurrentRestaurantOrdersAction([]));
	}, []);

	return (
		<TableWrapper>
			<MenuTitle>
				{t('common.choose')}
				<b>
					{' '}
					{t('common.menu')}
				</b>
			</MenuTitle>
			{/* eslint-disable */}
			{table?.localization?.restaurants.length > 0
                && table?.localization?.restaurants.map((restaurant) => {
                    const restaurantMiniatureUrl = restaurant?.images?.find((image) => image.type === ImageTypeEnum.MINIATURE)?.url;
                    console.log(restaurantMiniatureUrl)
                return (
                        <PanelInTable
                                key={restaurant.id}
                                actionLowerDescription={`${restaurant?.averageOrderPreparationTime ?? 20} min`}
                                actionName={restaurant.name}
                                actionUpperDescription={restaurant.description}
                                backgroundImagePath={restaurantMiniatureUrl ?? "/table/tło.svg"}
                                numberOfOpinions={restaurant.ratingsCount}
                                panelType={PanelInTableTypes.PANEL_IN_MENU}
                                ratingScore={(restaurant.ratingsSum / restaurant.ratingsCount)}
                                to={`/table/menu/details?tableId=${table.id}&restaurantId=${restaurant.id}`}
                        />
                )
            })}
			{/* eslint-enable */}
		</TableWrapper>
	);
};
export default Menu;
